import {EventEmitter, Inject, Injectable, Output} from '@angular/core';
import {take} from 'rxjs/operators';

import {IMenuItem, Thread, ThreadMessage, Unread} from '@nxt/model-core';

import {FireService} from './fire.service';
import {UserService} from './user.service';
import {PageService} from '../../shared/_services/page.service';

import {ClientService} from '../../shared/_services/client.service';
import {NxtFollowUpDialog} from '../../../nxto/src/app/_components/follow-up/nxt-follow-up.dialog';

// @ts-ignore
import {environment} from '@env/environment';
import {EThreadContext, MessagingService} from './messaging.service';
import {AssignDialog} from '../../../nxto/src/app/_components/users/assign.dialog';

@Injectable()
export class ThreadOptionsService {
    @Output() onChange:EventEmitter<any> = new EventEmitter<any>();

    constructor(
        public cSvc: ClientService,
        public uSvc: UserService,
        public fSvc: FireService,
        public pSvc: PageService,
        @Inject('AllSearchDialog') public AllSearchDialog: any
    ) {}

    async buildThreadOptions(
        mSvc: MessagingService,
        thread: Thread,
        context: EThreadContext,
        msg?: ThreadMessage
    ): Promise<IMenuItem[]> {

        let p: any = thread?.object;
        let result: any[] = [
            {
                label: 'Assign',
                click: () => {

                    this.pSvc.modal$.next({
                        component: AssignDialog,
                        label: `Assign Agents/Followers/Roles`,
                        onLoaded: (comp: AssignDialog) => {
                            comp.thread = thread;
                            comp.msg = msg;
                            comp.ngOnChanges();
                            comp.onClose.pipe(take(1))
                                .subscribe(([t,m]) => {
                                    if (thread && t) {
                                        thread = t;
                                    }
                                    if (msg && m) {
                                        msg = m;
                                    }
                                });
                        }
                    });

                }
            },
            {
                label: 'Move',
                click: () => {
                    this.move(mSvc,thread);
                }
            },
            {
                label: 'Add Follow-Up',
                click: () => {
                    this.pSvc.modal$.next({
                        label: 'Add Follow-Up',
                        component: NxtFollowUpDialog,
                        onLoaded: (comp: NxtFollowUpDialog) => {
                            comp.parent = msg||thread;
                            comp.ngOnChanges();
                            comp.addFollower((msg?.agents||[]).concat(thread?.agents||[]).concat([this.uSvc.user$.getValue()]));
                        }
                    })
                }
            }
        ];

        if (thread && p) {

            if (context === EThreadContext.UNREAD) {

                result.push({
                    label: 'Remove from My Unread',
                    click: async () => {
                        try {
                            let unread: Unread = (await this.fSvc.getObject(`users/${this.uSvc.user$.getValue().id}/clients/${this.cSvc.client_id}/unread/${msg?.id||thread.id}`)) as Unread;
                            if (unread._exists) {
                                await unread.delete();
                                this.onChange.emit();
                            }
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                });

            } else {

                if (msg) {
                    result.push({
                        label: msg.unread ? 'Mark Read' : 'Mark Unread',
                        click: async () => {
                            msg.unread = !msg.unread;
                            await msg.save();
                        }
                    });
                } else if (thread) {
                    result.push({
                        label: 'Mark All Msgs Read',
                        click: async () => {
                            this.pSvc.blocking$.next(true);
                            try {

                                let msgs = await this.fSvc.getColl(`clients/${this.cSvc.client_id}/threadsmsgs`, [{ name: 'where', args: ['tRef','==',thread._docRef]}]).toPromise();
                                await Promise.all(msgs?.map(async doc => {
                                    await doc.ref.update({unread: false});
                                }));
                                // Update the thread's activity so the display immediately updates (instead
                                // of waiting for db to do its work and the UI to listen and catch up).
                                thread.activity = thread.activity?.map(a => {
                                    a.unread = false;
                                    return a;
                                })||[];

                            } catch (e) {
                                this.pSvc.alert$.next(e);
                            }
                            this.pSvc.blocking$.next(false);
                        }
                    });
                }

                result.push({
                    label: 'Add to My Unread',
                    click: async () => {
                        try {
                            let unread: Unread = (await this.fSvc.getObject(`users/${this.uSvc.user$.getValue().id}/clients/${this.cSvc.client_id}/unread/${msg?.id||thread.id}`)) as Unread;
                            unread.ref = msg?._docRef || thread._docRef;
                            await unread.save();
                        } catch (e) {
                            console.warn(e);
                        }
                    }
                });

            }

            if (context !== EThreadContext.DETAILS) {
                let pName: string = p.toString();
                result.push({
                    label: `View ${pName.length > 25 ? pName.substring(0, 25)+'...' : pName}`,
                    click: () => {
                        mSvc.navByRef(thread.ref);
                    }
                });
            }

            if (this.uSvc.isRole('admin')) {
                if (environment.type !== 'prod') {
                    result.push({
                        label: 'Delete Thread & Messages',
                        click: async () => {
                            this.pSvc.blocking$.next(true);
                            try {
                                await thread.delete();
                            } catch (e) {
                                console.warn(2, e);
                            }
                            this.pSvc.blocking$.next(false);
                            mSvc.thread$.next(null);
                        }
                    })
                }
                if (context === EThreadContext.INBOX) {

                    result.push({
                        label: 'Hide Thread & Messages',
                        click: async () => {
                            this.pSvc.blocking$.next(true);
                            try {
                                thread.active = false;
                                await thread.save();
                            } catch (e) {
                                console.warn(2, e);
                            }
                            this.pSvc.blocking$.next(false);
                            mSvc.thread$.next(null);
                        }
                    })

                }

            }

        }

        return result;
    }


    async move(mSvc: MessagingService, thread: Thread) {

        this.pSvc.modal$.next({
            component: this.AllSearchDialog,
            label: 'Locate Destination for Thread',
            onLoaded: async (comp: any) => {
                comp.exclude = thread.object;
                comp.onClick.pipe(take(1))
                    .subscribe(
                        async item => {
                            if (item) {
                                this.pSvc.blocking$.next(true);
                                try {
                                    let from: string = thread.object?._docRef?.path;
                                    if (!from) {
                                        throw {
                                            title: 'Cannot Move!',
                                            message: 'Intended destination not found.'
                                        }
                                    }
                                    let to: string = `clients/${this.cSvc.client_id}/${item._type}/${item.id}`;
                                    if (item._type.match(/threads/) && item.ref?.path) {
                                        to = item.ref?.path;
                                    }
                                    let result: any = await this.cSvc.callAPI('/messaging/move', 'post',{
                                        from: from, to: to, path: thread._docRef.path
                                    });
                                    if (result?.threads || result?.threadsmsgs) {
                                        thread = (await this.fSvc.getObject(thread._docRef.path)) as Thread;
                                        await thread.loadAll({
                                            loadAllFn: mSvc.loadAll,
                                            olm: mSvc.olm
                                        });
                                        mSvc.thread$.next(thread);
                                    }
                                    this.pSvc.notification$.next({
                                        title: 'Threads Moved',
                                        message: 'Click View to see threads at their new location.',
                                        buttons: [
                                            {
                                                label: 'View',
                                                click: () => {
                                                    mSvc.navByRef(null, item._type, item.id);
                                                }
                                            }
                                        ]
                                    });
                                    comp.onClose.emit();

                                } catch (e) {
                                    this.pSvc.alert$.next(e);
                                }
                                this.pSvc.blocking$.next(false);
                            }
                        }
                    )
            }
        });

    }

}
