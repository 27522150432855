import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageService} from "@library/shared/_services/page.service";
import {AttachmentModalComponent} from "./attachment-modal.component";
import {ClientService} from "@library/shared/_services/client.service";
import {FireService} from '@library/nxt/_services/fire.service';
import {CloudFile} from '@nxt/model-core';
import {firstValueFrom} from 'rxjs';

@Component({
    standalone: true,
    imports: [CommonModule],
    selector: 'attachment-viewer',
    template: `
        <div [class]="class" *ngIf="files?.length">
            <ng-container *ngIf="condensed">
                <span *ngFor="let file of files; let i = index;" (click)="openAttachment(i)"
                      class="chicklet mr-1 text-xs">
                    {{ file.name }}
                </span>
            </ng-container>
            <ng-container *ngIf="!condensed">
                <div class="p-2 m-4 mt-1 grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
                    <div *ngFor="let file of files; let i = index;"
                         class="cursor-pointer items-center text-center min-h-100 hover:bg-gray-200 text-gray-600"
                         (click)="openAttachment(i)">
                        <ng-container [ngSwitch]="file.type">
                            <img *ngSwitchCase="'image/jpg'" [src]="file.url" class="object-cover">
                            <img *ngSwitchCase="'image/jpeg'" [src]="file.url" class="object-cover">
                            <img *ngSwitchCase="'image/png'" [src]="file.url" class="object-cover">
                            <img *ngSwitchCase="'image/gif'" [src]="file.url" class="object-cover">
                            <video *ngSwitchCase="'image/mp4'" class="object-cover" [src]="file.url"></video>
                            <video *ngSwitchCase="'image/mov'" class="object-cover" [src]="file.url"></video>
                            <div class="m-2 italic text-xs" *ngSwitchDefault>
                                <p class="truncate">
                                    {{ file.name }}
                                </p>
                                <p class="mt-3">
                                    {{ file.type }}
                                </p>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </div>
    `
})
export class AttachmentViewerComponent implements OnChanges {
    @Input() parent: any;
    @Input() condensed: boolean;
    @Input() class: string = '';
    files: CloudFile[];

    constructor(
        private pSvc: PageService,
        private cSvc: ClientService,
        private fSvc: FireService
    ) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (this.parent?._exists && (changes.parent?.currentValue?.id !== changes.parent?.previousValue?.id || changes.parent?.currentValue?._exists !== changes.parent?.previousValue?._exists)) {
            let res = await firstValueFrom( this.fSvc.getColl(`${this.parent._docRef.path}/files`) );
            this.files = res?.length ? res.map(r => new CloudFile(r)) : [];
        }
    }

    openAttachment(index: number) {
        this.pSvc.modal$.next({
            component: AttachmentModalComponent,
            onLoaded: (comp: any) => {
                comp.files = this.files;
                comp.contact = this.parent.contact;
                comp.saveToParent = this.cSvc.name_key === 'EQIP';
                comp.parent = this.parent?.object;
                comp.currentIndex = index;
            },
            styles: {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                'z-index': '1000',
                width: '80vw',
                height: '90vh',
                display: 'flex',
                flexDirection: 'column',
            },
        });
    }
}
