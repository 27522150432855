"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvianisService = exports.Service = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Service extends model_core_1.Base {
    constructor(data, olMap) {
        super('services', 6);
        this.source = '';
        this.type = index_1.EServiceTypes.NONE;
        this.crewOnly = false;
        this.notes = '';
        Object.assign(this, (data === null || data === void 0 ? void 0 : data.data) ? data.data() : data);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Service);
    }
}
exports.Service = Service;
class AvianisService extends Service {
}
exports.AvianisService = AvianisService;
index_1.olm.services = (ref, map) => {
    return new Service(ref, map);
};
