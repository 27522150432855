import {
    AfterViewInit,
    Component, ElementRef, EventEmitter, Input, OnChanges,
    Output, QueryList, SimpleChanges, ViewChildren
} from '@angular/core';
import {FireService, IFirestoreQuery} from '../_services/fire.service';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {SpinnerComponent} from '../../shared/spinner/spinner.component';
import {AsyncPipe, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {PageService} from '../../shared/_services/page.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {Base, formatDate, ILoadAllOpts, loadObject, Thread, ThreadMessage} from '@nxt/model-core';
import {DateHeaderComponent} from '../../shared/header/date-header.component';
import {PipesModule} from '../../shared/_pipes/pipes';
import {MessagingService} from '../_services/messaging.service';

// @ts-ignore
import {environment} from '@env/environment';
import {RouterModule} from '@angular/router';
import { filter, takeUntil} from 'rxjs/operators';

@Component({
    standalone: true,
    imports: [
        PipesModule, RouterModule, SpinnerComponent, AsyncPipe, NgForOf,
        NgTemplateOutlet, NgIf, DateHeaderComponent
    ],
    selector: 'scrollable-generic-list',
    template: `
        <div *ngIf="!(more$|async) && items && !items.length && !hideEmpty"
             class="bg-accent-100 border-t border-b border-dark-500 text-dark-700 px-4 py-3 flex max-w-400 m-auto mt-4"
        >
            <div class="flex-grow">
                <span class="font-bold">No {{label}} Found. </span> <span>{{noResultsMsg||''}}</span>
                <span *ngIf="source==='a'">
                    <br/><br/>
                    <a class="underline cursor-pointer" (click)="items=null;onClear.emit()">Clear Search</a>
                </span>
            </div>
        </div>

        <div [class]="class">
            <div class="item-list">
                <ul role="list" [class]="padding">
                    <li #itemTemplates [id]="item.id" *ngFor="let item of items | filterBy: { term: searchTerm, properties: searchProperties }; let i = index; trackBy: pSvc.trackBy()">
                        <date-header *ngIf="dateHeader" [label]="getDateHeader(item, i)"></date-header>
                        <ng-container *ngTemplateOutlet="itemTemplate; context:{item:item, items:items, i:i}"></ng-container>
                    </li>
                </ul>
            </div>
            <spinner class="m-1 mx-auto h-10 w-10 text-dark" *ngIf="(more$|async) && !doneLoading"></spinner>
        </div>
    `
})
export class ScrollableGenericList extends OnDestroyPage implements OnChanges, AfterViewInit {
    @ViewChildren('itemTemplates') itemTemplates: QueryList<ElementRef>;
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Output() onNextPage: EventEmitter<any> = new EventEmitter<any>();
    @Output() onItemsLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Input() path: string;
    @Input() class: string;
    @Input() label: string = 'Items';
    @Input() padding: string = '';
    @Input() pageSize: number = 15;
    @Input() itemTemplate: any;
    @Input() searchTerm: string = '';
    @Input() searchProperties: string[] = [];
    @Input() baseQuery: IFirestoreQuery[];
    @Input() dateHeader: string;
    @Input() dateHeaderPrefix: string = '';
    @Input() parent: any;
    @Input() watch: boolean;
    @Input() autoStart: boolean;
    @Input() items: any[];
    @Input() hideEmpty: boolean;
    @Input() exclude: Base;
    @Input() debug: boolean|string;
    @Input() loadAll: boolean;
    @Input() loadAllOpts: ILoadAllOpts;
    @Input() noResultsMsg: string;
    more$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    source: 'a' | 'f';
    lastItem: any;
    @Input() doneLoading: boolean;
    sortBy: string;
    env = environment;
    sub: Subscription;
    delta$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    changed$: Observable<any>;
    observer: IntersectionObserver;

    constructor(
        private fSvc: FireService,
        public pSvc: PageService,
        private mSvc: MessagingService,
        public eRef: ElementRef
    ) {
        super();
        this.changed$ = this.delta$.pipe(filter(u => (u===true)));
    }

    ngOnDestroy() {
        this.observer?.disconnect();
        super.ngOnDestroy();
    }

    // This handles static loader where there is no related algolia search happening first.
    // In the parent compoennt, set the 'source' and 'query' and 'path' properties and the load happens.
    ngOnChanges(changes: SimpleChanges) {
        if (this.path && this.baseQuery && this.autoStart) {
            let delta: any = ['path','baseQuery'].reduce((changed:any,p:string) => {
                if (changes[p] && changes[p].currentValue && changes[p].currentValue !== changes[p].previousValue) {
                    changed[p] = true;
                }
                return changed;
            }, {});

            if (delta.path || delta.baseQuery) {
                this.delta$.next(true);
                this.sub?.unsubscribe();
                this.loadData(true);
            }
        }
    }

    /**
     * This detects when the last item in the list is in view, and checks to
     * see if we need to load another page of data.  No more scroll detection!
     */
    ngAfterViewInit() {

        this.itemTemplates.changes
            .pipe(takeUntil(this.d$))
            .subscribe(() => {

                if (this.itemTemplates.length && !(this.itemTemplates.length % this.pageSize)) {
                    const lastItem = this.itemTemplates.last.nativeElement;
                    this.observer?.disconnect();
                    this.observer = new IntersectionObserver((entries) => {
                        entries.forEach(entry => {
                            if (entry.isIntersecting) {
                                if (this.pageSize && !this.more$.getValue()) {
                                    if (!this.doneLoading) {
                                        this.observer?.disconnect();
                                        this.more$.next(true);
                                        this.nextPage();
                                    }
                                }
                            }
                        });
                    });
                    this.observer.observe(lastItem);
                }
            });
    }

    async loadData(clear?: boolean) {
        if (clear) {
            this.source = 'f';
            this.items = [];
        }
        if (this.source === 'f') {
            await this.load(this.baseQuery);
        }
    }

    async load(query: IFirestoreQuery[], append?: boolean, path?: string) {
        this.source = 'f';
        this.path = path || this.path;
        if (this.path && query) {
            this.more$.next(true);
            this.delta$.next(false);

            if (!append) {
                this.items = [];
            } else if (this.lastItem) {
                query = query.concat([{name: 'startAfter', args: [this.lastItem]}]);
            }

            this.more$.next(true);
            if (this.pageSize) {
                query = query.concat([{name:'limit', args:[this.pageSize]}]);
            }
            this.sortBy = query.find(i => i.name==='orderBy')?.args[0];

            try {

                this.fSvc.getColl(this.path, query, this.d$)
                    .pipe(takeUntil(this.changed$))
                    .subscribe(async res => {
                        if (res) {
                            if (!res.length) {
                                if (append) {
                                    this.doneLoading = true;
                                } else {
                                    this.items = [];
                                }
                            } else if (res.length) {
                                this.lastItem = res[res.length - 1];
                                this.doneLoading = (res.length < this.pageSize);
                                let opts: any = this.loadAllOpts || {olm: this.mSvc.olm, default_client_id: environment.default_client?.id};
                                let items: any[] = await Promise.all(res?.map(async item => {
                                    item = loadObject(item, opts)
                                    if (item._type==='unread') {
                                        item = await this.loadUnread(item);
                                    }
                                    if (this.loadAll || this.loadAllOpts) {
                                        await item.loadAll(opts);
                                    }
                                    return item;
                                }));
                                if (append) {
                                    this.items = this.items.concat(items);
                                } else {
                                    this.items = items;
                                }
                            }
                        }
                        this.watchData(query);
                        this.more$.next(false);
                        this.onItemsLoaded.emit();
                    },
                        e => {
                            console.warn(e.toString(), this.path);
                            if (e.toString().match(/permission-denied/)) {
                                this.pSvc.notification$.next({
                                    title: 'Permission Denied',
                                    message: `You do not have permissions configured for requested content. Please contact an admin for help.`
                                    // TODO Would be nice to have an email link or way to create a ticket.
                                })
                            }
                            this.more$.next(false);
                        },
                        () => {
                            // console.log('get stopped', this.delta$.getValue());
                        }
                );

            } catch (e) {
                console.error(e);
            }

        }
    }

    async loadUnread(item: any) {
        let obj: ThreadMessage | Thread = loadObject(await item.ref.get(), {olm: this.fSvc.olm});
        if (obj?._exists) {
            obj._unread = item;
        }
        return obj;
    }

    watchData(query: IFirestoreQuery[]) {
        if (this.watch && query) {
            let direction: string = query.find(i => i.name==='orderBy')?.args[1];
            if (this.lastItem) {
                query = query.concat({name: 'endAt', args: [this.lastItem]})
            }
            this.fSvc.watchState(this.path, query, this.d$)
                .pipe(takeUntil(this.changed$))
                .subscribe(
                    async res => {
                        // Do a synchronous loop so the index locations are right. Async results in chaos in the array
                        // overlapping indexes as multiple process add/remove items without knowledge of each other.
                        if (this.source === 'f') {
                            for (let item of res) {

                                let i: Base = loadObject(item.payload.doc, {olm:this.mSvc.olm});
                                if (i._type==='unread') {
                                    i = await this.loadUnread(i);
                                }
                                let n: number = this.items.findIndex(m => m.id === i.id);
                                // if (this.debug) console.log(n, item.type);
                                if (n === -1 && item.type === 'added') {

                                    let n: number =  this.sortBy ? this.items.findIndex(m => {
                                        // console.log(this.sortBy+','+direction,eval(`m.${this.sortBy}`));
                                        // console.log('i.${sortBy}',eval(`i.${this.sortBy}`));
                                        if (direction === 'asc') {
                                            return eval(`m.${this.sortBy}`) > eval(`i.${this.sortBy}`)
                                        } else {
                                            return eval(`m.${this.sortBy}`) < eval(`i.${this.sortBy}`)
                                        }
                                    }) : null;

                                    if (n > -1) {
                                        if (this.debug) console.log(`add at ${n}`);
                                        this.items.splice(n, 0, i);
                                    } else if (direction==='desc') {
                                        if (this.debug) console.log(`add at end`);
                                        this.items.push(i);
                                    } else {
                                        if (this.debug) console.log(`add at start`);
                                        this.items.splice(0, 0, i);
                                    }

                                } else if (n > -1) {
                                    if (item.type === 'modified') {
                                        // console.log(`update at ${n}`, item);
                                        this.items[n] = i;
                                    } else if (item.type === 'removed' || item.type === 'deleted') {
                                        // console.log(`remove at ${n}`, item);
                                        this.items.splice(n, 1);
                                    }
                                }
                            }
                            // This was the only way to force the page to re-draw.
                            this.items = [...this.items];
                        }


                    },
                    e => {
                        console.warn(e.toString(), this.path);
                        if (e.toString().match(/permission-denied/)) {
                            this.pSvc.notification$.next({
                                title: 'Permission Denied',
                                message: `You do not have permissions configured for requested content. Please contact an admin for help.`
                                // TODO Would be nice to have an email link or way to create a ticket.
                            })
                        }
                        this.more$.next(false);
                    },
                        () => {
                            // console.log('watch stopped', this.delta$.getValue());
                        }
                    );
        }
    }

    async handleAlgoliaResults([results, append]) {
        this.source = 'a';
        let opts: any = {olm: this.mSvc.olm, default_client_id: environment.default_client?.id};

        let loadItem = (item) => {
            let i: Base = loadObject(item, opts);
            let parts: string[] = item['objectID'].split('-');
            if (parts[1] !== 'undefined') {
                i._docRef = this.fSvc.fs.firestore.doc(parts[1]);
                if (this.loadAll) {
                   i.loadAll(opts);
                }
            } else {
                console.warn('BAD SEARCH RECORD', item);
            }
            Object.keys(item['_highlightResult']).forEach(p => {
                if (item['_highlightResult'][p].matchLevel==='full') {
                    i[`${p}_match`] = item['_highlightResult'][p].value;
                }
            });
            return i;
        };

        if (!append) {
            if (results?.hits?.length) {
                this.items = results?.hits?.reduce((items,item) => {
                    if (this.exclude) {
                        if (item?._type?.match(/threads/)) {
                            if (item?.object?.id
                                && item.object?.id !== this.exclude.id) {
                                items.push(loadItem(item));
                            }
                        } else if (item.id !== this.exclude.id) {
                            items.push(loadItem(item))
                        }
                    } else {
                        items.push(loadItem(item))
                    }
                    return items;
                },[]);
            } else {
                this.items = [];
            }
        } else if (results?.hits?.length) {
            this.items = this.items.concat(results?.hits?.reduce((items,item) => {
                if (!this.exclude || item.id !== this.exclude.id) {
                    items.push(loadItem(item))
                }
                return items;
            },[]));
        }
        this.more$.next(false);
    }

    nextPage() {
        if (this.source === 'f') {
            if (this.lastItem) {
                this.load(this.baseQuery, true);
            } else {
                this.more$.next(false);
            }
        } else {
            this.onNextPage.emit();
            this.more$.next(false);
        }
    }

    getDateHeader(item: any, n: number, property?: string) {
        property = property || this.sortBy || 'departure_date';
        if (item && item._type && item[property]) {
            let previous: any = this.items[n - 1];
            if (!item['_date']) {
                let d: number = eval(`item.${property}`);
                if (!d) {
                    if (item.legs?.length && item.legs[0].times?.takeoffUTC) {
                        d = item.legs[0].times?.takeoffUTC;
                    } else {
                        d = item.date;
                    }
                }
                item['_date'] = `${formatDate(new Date(d), this.dateHeader || 'MMM d')}`;
            }
            if (!previous || item['_date'] !== previous['_date']) {
                return this.dateHeaderPrefix+item['_date'];
            }
        }
    }

}
