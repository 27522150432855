import {
    Component,
    EventEmitter,
    Output,
    Input,
    ViewChild,
    ElementRef,
    OnChanges,
    SimpleChanges,
    Inject
} from '@angular/core';
import {Subscription} from 'rxjs';
import {CommonModule} from '@angular/common';

import {ScrollableDivDirective} from '@library/shared/scrollable-div/scrollable-div';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {MessageRefIcon} from '@library/nxt/list/message-ref-icon';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {FollowersComponent} from './followers.component';

import {IMenuItem, Thread, ThreadMessage, EMedium} from '@nxt/model-core';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';

import {MessageItemComponent} from './message-item.component';
import {ScrollableThreadTriggeredList} from '@library/nxt/list/scrollable-thread-triggered-list';
import {ComposeMsgComponent} from './compose-msg.component';
import {FollowersByRoleComponent} from './followers-by-role.component';
import {PageService} from '@library/shared/_services/page.service';


@Component({
    standalone: true,
    imports: [
        CommonModule, ScrollableDivDirective, MessageMediumIcon,
        MessageRefIcon, PopButtonComponent, FollowersComponent,
        MessageItemComponent,
        ScrollableThreadTriggeredList, ComposeMsgComponent, FollowersByRoleComponent
    ],
    selector: 'thread-detail',
    template: `
        <div class="h-full bg-white" *ngIf="thread">
            
            <div class="flex justify-between p-2" *ngIf="thread?.object?._type !== 'clients'">
                <div class="flex">
                    <button *ngIf="thread?.object?._type !== 'tasks' && thread?.object?._type !== 'pricing' && thread?.object?._type !== 'deployments' && thread?.object?._type !== 'work_orders'"
                            class="btn-gray btn-xs ml-1" 
                            title="Move Thread" 
                             (click)="tOptSvc.move(mSvc,thread)"
                    >
                        Move
                    </button>
                    <button  *ngIf="thread?.object?._type !== 'tasks' && thread?.object?._type !== 'pricing' && thread?.object?._type !== 'deployments' && thread?.object?._type !== 'work_orders'"
                             class="btn-gray btn-xs ml-1" 
                             title="Move Thread" 
                             (click)="msgList.print()"
                    >
                        Print
                    </button>
                </div>
                
                <div class="flex">
                    <followers
                            label="Agents"
                            limit="3"
                            [parent]="thread"
                            property="agents"
                            (onChange)="update('agents',$event)"
                    ></followers>
                    <followers
                            label="Followers"
                            limit="4"
                            [parent]="thread"
                            property="followers"
                            (onChange)="update('followers',$event)"
                    ></followers>
                    <followers-by-role
                            [parent]="thread"
                            label="Roles"
                            property="roles"
                            (onChange)="update('roles',$event)"
                    ></followers-by-role>
                </div>
            </div>

            <!-- MSG COMPOSER -->
            <compose-msg
                    [thread]="thread"
                    [message]="mSvc.activeMsg$|async"
                    [context]="context"
                    (onThread)="confirmThread($event)"
                    (onLoaded)="onCompose.emit($event);"
                    (onMessage)="onMessage.emit($event)"
                    [templateDocument]="mSvc.templateDocument$|async"
                    [isTemplateModal]="isTemplateModal"
            ></compose-msg>

            <scrollable-thread-triggered-list #msgList [thread]="thread"></scrollable-thread-triggered-list>
            
        </div>
    `
})
export class ThreadDetailComponent extends OnDestroyPage implements OnChanges {
    @ViewChild('msgList', {static:true}) msgList: ScrollableThreadTriggeredList;
    @Output() onMessage: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Output() onCompose: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @Input() thread: Thread;
    @Input() context: EThreadContext;
    @Input() isTemplateModal: boolean = true;
    EThreadContext = EThreadContext;
    EMedium = EMedium;
    message: ThreadMessage;
    loaded: boolean;
    buttons: IMenuItem[] = [];
    sub: Subscription;
    medium: any;
    pagingSize: number = 15;

    // get subject(): string {
    //     switch (this.thread?.medium) {
    //         case EMedium.CHAT:
    //             switch (this.thread?.object?._type) {
    //                 case 'tasks':
    //                 case 'deployments':
    //                     return this.thread?.object.name;
    //             }
    //             break;
    //         default:
    //             return this.thread?.subject || '';
    //     }
    // }

    constructor(
        private fSvc: FireService,
        private pSvc: PageService,
        public mSvc: MessagingService,
        @Inject('ThreadOptionsService') public tOptSvc: any
    ) {

        super();

    }

    async ngOnChanges(changes?: SimpleChanges) {
        if (changes.thread?.currentValue?.id && changes.thread?.currentValue?.id !== changes.thread?.previousValue?.id) {
            if (this.thread.medium === EMedium.CHAT && !this.mSvc.getMsg(this.thread?.id)?.id) {
                await this.mSvc.startMessage(this.thread.object, this.thread, [], [], EMedium.CHAT);
            }
            this.mSvc.activeMsg$.next(this.mSvc.getMsg(this.thread?.id));
        }
    }

    async confirmThread(threadPath: string) {
        if (threadPath && !this.thread?._exists) {
            this.thread = (await this.fSvc.getObject(threadPath)) as Thread;
        }
    }

    async update(property: string, users: any) {
        this.thread[property] =  users;
        if (property === 'followers' && this.thread.agents?.find(a => users.find(u => u.id === a.id))) {
            this.pSvc.notification$.next({
                title: 'Already Agent!',
                message: `You are already following this thread as an agent.`
            });
        }
        if (this.thread._exists) {
            await this.thread.save();
        }
    }

}
