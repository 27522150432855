import {Component, EventEmitter, OnChanges, Input, Output, SimpleChanges} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {InputDateComponent} from './input-date.component';
import {endOfDay, format, parse, startOfDay} from 'date-fns';
import {FooterButtonsComponent} from '../../nxt/footer/footer-buttons.component';
import {IMenuItem} from '@nxt/model-core';
import {OnDestroyPage} from '../_inherited/ondestroy.page';
import {takeUntil} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    selector: 'date-range-dialog',
    standalone: true,
    imports: [
        InputDateComponent,
        FooterButtonsComponent
    ],
    template: `
        <div class="p-4 grid grid-cols-2 gap-2">
            <input-date label="Start"
                        [form]="form"
                        [includeTime]="includeTime"
                        [requireTime]="requireTime"
                        controlName="start"
            ></input-date>
            <input-date label="End"
                        [minDate]="start"
                        [form]="form"
                        [includeTime]="includeTime"
                        [requireTime]="requireTime"
                        controlName="end"
            ></input-date>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>
    `
})
export class DateRangeDialog extends OnDestroyPage implements OnChanges {
    @Output() onClose: EventEmitter<{ start: Date, end: Date }> = new EventEmitter(null);
    @Input() start: Date;
    @Input() end: Date;
    @Input() includeTime: boolean = false;
    @Input() requireTime: boolean = false;
    form: UntypedFormGroup;
    buttons: IMenuItem[] = [
        {
            label: 'Cancel',
            click: () => { this.onClose.emit(); }
        },
        {
            label: 'Set',
            class: 'btn-dark',
            click: () => {
                if (this.end && this.start && this.end.valueOf() >= this.start.valueOf()) {
                    if (this.end.valueOf() === this.start.valueOf()) {
                        this.end = endOfDay(this.end);
                    }
                    this.onClose.emit({ start: this.start, end: this.end });
                }
            }
        }
    ];
    sub: Subscription;

    constructor(public fb: UntypedFormBuilder) {
        super();
    }

    ngOnChanges(changes?: SimpleChanges) {
        this.form = this.fb.group({
            start: [this.start, Validators.required],
            end: [this.end, Validators.required]
        });
        this.sub?.unsubscribe();
        this.sub = this.form.valueChanges
            .pipe(takeUntil(this.d$))
            .subscribe(v => {
                this.start = v.start;
                this.end = v.end;
            });
    }
}
