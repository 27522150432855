import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {Client, Thread, ThreadMessage} from '@nxt/model-core';
import {EThreadContext, MessagingService} from '../_services/messaging.service';
import {UserService} from '../_services/user.service';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';

import {NotificationSoundsComponent} from '../../../nxto/src/app/threads/_components/notification-sounds.component';
import {UnreadListComponent} from '../../../nxto/src/app/threads/_components/unread-list.component';
import {ItemListComponent} from '../list/item-list.component';
import {IconsComponent} from '../../shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, NotificationSoundsComponent, UnreadListComponent, ItemListComponent, IconsComponent,
    ],
    selector: 'notification-sidebar',
    template: `
        <div [class]="(show===true)
            ? 'fixed inset-0 overflow-hidden transform transition ease-in-out duration-500 translate-x-0 z-30'
            : (show===false)
                ? 'fixed inset-0 overflow-hidden transform transition ease-in-out duration-500 translate-x-full z-30'
                : 'fixed inset-0 overflow-hidden translate-x-full z-30'"
        >
            <div class="absolute inset-0 overflow-hidden">

                <div class="absolute inset-0 bg-gray-500 ease-in-out duration-500 transition-opacity bg-opacity-75"
                     aria-hidden="true" id="overlay"></div>
                <div class="fixed inset-y-0 right-0 max-w-full flex">

                    <div class="w-screen max-w-md"
                         [class]="(show===true) ? 'translate-x-0 transform transition ease-in-out duration-500 sm:duration-700' : (show===false) ? 'translate-x-full transform transition ease-in-out duration-500 sm:duration-700' : 'translate-x-full transform  w-screen max-w-md'">

                        <div class="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                            <div class="py-6 px-4 bg-dark-700">
                                <div class="flex items-center">
                                    <button (click)="onToggle.emit(false)"
                                            class="rounded-md text-gray-300 mr-1 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                                        <span class="sr-only">Close panel</span>
                                        <icon name="heroicon-outline-x" class="h-6 w-6"></icon>
                                    </button>

                                    <a (click)="onToggle.emit(false)"
                                       routerLink="/{{cSvc.name_key}}/threads/inbox/unread"
                                       class="btn-dark bg-dark-700 hover:text-dark-100">
                                        My Unread
                                    </a>
<!--                                    <notification-sounds (click)="onToggle.emit(false)"></notification-sounds>-->
                                </div>
                            </div>

                            <unread-list
                                    [user]="uSvc.user$|async"
                                    [client]="cSvc.client$|async"
                                    [clients]="cSvc.clients$|async"
                                    [show]="show"
                            ></unread-list>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    `
})
export class NotificationSidebarComponent {
    @Input() show: boolean;
    @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
    EThreadContext = EThreadContext;
    clients: Client[];

    constructor(
        public cSvc: ClientService,
        public uSvc: UserService,
        public mSvc: MessagingService,
        private pSvc: PageService
    ) {

        this.pSvc.clickEsc$
            .subscribe(
                res => {
                    if (res && !this.pSvc.modal$.getValue() && !this.mSvc.thread$.getValue()) {
                        this.onToggle.emit(false);
                    }
                }
            );
        this.pSvc.nav$
            .subscribe(p => {
                this.onToggle.emit(false);
            });
    }

    async handleClick(item?:Thread|ThreadMessage) {
        this.onToggle.emit(false);
    }


}
