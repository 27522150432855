import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgModule,
    OnDestroy,
    Output,
    ViewChild
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReplaySubject} from 'rxjs';
import {Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {createPopper} from "@popperjs/core";

import {IMenuItem} from '@nxt/model-core';
import {PageService} from '../_services/page.service';


@Component({
    standalone: true,
    selector: 'generic-right-click-menu',
    imports: [
        CommonModule
    ],
    template: `
        <div class="z-40" style="min-width: 12rem;" #popoverDropdownRef>
            <div class="bg-white text-base float-left py-2 list-none text-left rounded shadow-lg mb-1 border-dark"
                 style="min-width: 12rem; border-width: 1px;"
            >
                <ng-container *ngFor="let item of items">
                    <ng-container *ngIf="!item.hide">
                        <a (click)="handleClick(item)" [style.color]="item.color"
                           class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                        >
                            <!--                    <icon *ngIf="item.icon" [name]="item.icon" class="h-4 w-4 mr-4"></icon>-->
                            <div>{{ item.label ? item.label : item }}</div>
                            <span class="text-xs font-italic" *ngIf="item.tooltip">{{ item.tooltip }}</span>
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </div>
    `
})
export class RightClickMenu implements OnDestroy, AfterViewInit {
    private d$: ReplaySubject<boolean> = new ReplaySubject(1);

    ngOnDestroy() {
        this.d$.next(true);
        this.d$.complete();
    }

    @Input() event: MouseEvent;
    @Input() items: IMenuItem[];
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild("popoverDropdownRef", {static: false}) popoverDropdownRef: ElementRef;

    constructor(
        private router: Router,
        private pSvc: PageService,
        private eRef: ElementRef
    ) {
    }

    init() {

        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e
                        && e.target
                        && this.eRef?.nativeElement
                        && !this.eRef.nativeElement.contains(e.target)
                    ) {
                        this.onClose.emit();
                    }
                }
            );

    }

    ngAfterViewInit() {
        createPopper(
            {
                getBoundingClientRect: this.generateGetBoundingClientRect(this.event?.x, this.event.y),
            } as HTMLElement,
            this.popoverDropdownRef.nativeElement,
            {
                placement: 'auto'
            }
        );
    }

    async handleClick(item: IMenuItem) {
        if (item.click) {
            item.click();
        } else if (item.routerLink) {
            await this.router.navigate([item.routerLink]);
        }
        this.onClose.emit(item);
    }

    generateGetBoundingClientRect(x = 0, y = 0) {
        return () => ({
            width: 0,
            height: 0,
            top: y,
            right: x,
            bottom: y,
            left: x,
        });
    }
}
