import {
    Component,
    EventEmitter,
    Input, OnChanges,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';

import {CommonModule} from '@angular/common';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {Thread, ThreadMessage} from '@nxt/model-core';
import {MessagingService} from '../_services/messaging.service';
import {MessageItemComponent} from '../../../nxto/src/app/threads/_components/message-item.component';
import {ScrollableGenericList} from './scrollable-generic-list';
import {IFirestoreQuery} from '../_services/fire.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        ScrollableGenericList,
        MessageItemComponent
    ],
    selector: 'scrollable-thread-triggered-list',
    template: `
        
        <scrollable-generic-list
                #itemList
                pageSize="15"
                [itemTemplate]="itemTemplate"
                [hideEmpty]="!thread._exists"
                label="Messages"
                [autoStart]="true"
                [loadAll]="false"
                [watch]="true"
                [path]="path"
                [baseQuery]="query"
                (onItemsLoaded)="onItemsLoaded.emit()"
        ></scrollable-generic-list>

        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <message-item
                    [index]="i"
                    (onSelect)="mSvc.msg$.next($event)"
                    [selected]="(mSvc.msg$|async)?.id === item.id"
                    (onDelete)="handleDelete($event)"
                    [message]="item"
                    [parent]="mSvc.context$|async"></message-item>
        </ng-template>
    `
})
export class ScrollableThreadTriggeredList extends OnDestroyPage implements OnChanges {
    @ViewChild('itemList', {static:true}) itemList: ScrollableGenericList;
    @Output() onItemsLoaded: EventEmitter<any> = new EventEmitter<any>();
    @Input() thread: Thread;
    path: string;
    query: IFirestoreQuery[];

    constructor(
        public cSvc: ClientService,
        public pSvc: PageService,
        public mSvc: MessagingService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.thread?.currentValue?.id && changes.thread?.currentValue?.id !== changes.thread?.previousValue?.id) {
            this.loadThread();
        }
    }

    loadThread() {
        if (this.thread?._docRef && this.cSvc.client_id && this.itemList) {
            this.path = `clients/${this.cSvc.client_id}/threadsmsgs`;
            this.query = [
                {name: 'where', args: ['tRef','==', this.thread._docRef]},
                {name: 'orderBy', args: ['date', 'desc']}
            ];
        }
    }

    handleDelete(msg: ThreadMessage) {
        if (!this.itemList.items?.length || (this.itemList.items?.find(i => i.id === msg.id) && this.itemList.items?.length === 1)) {
            this.thread.delete();
        }
    }

    print() {
        let styles: string = 'styles.css';
        let tags = document.getElementsByTagName('link');
        for (let i = 0; i < tags.length; i++) {
            if (tags[i].href.match(/styles.css$/)) {
                styles = tags[i].href;
                break;
            }
        }
        if (!styles) {
            styles = `${window.location.origin}/styles.css`;
        }
        var mywindow = window.open('', 'PRINT', 'height=800,width=1160');
        mywindow.document.write(`<html><head><title>Thread Details</title><link rel="stylesheet" href="${styles}"></head>`);
        mywindow.document.write('</head><body >');
        mywindow.document.write(this.itemList.eRef.nativeElement.innerHTML.replace(this.itemList.class, ''));
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/
    }

}
