"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAvianisServiceRequestStatus = exports.EServiceTypes = void 0;
var EServiceTypes;
(function (EServiceTypes) {
    EServiceTypes["CATERING"] = "catering";
    EServiceTypes["FBO"] = "fbo";
    EServiceTypes["FUEL"] = "fuel";
    EServiceTypes["GROUND_TRANSPORT"] = "groundTransportation";
    EServiceTypes["LODGING"] = "lodging";
    EServiceTypes["HANDLING"] = "HANDLING";
    EServiceTypes["HANGER"] = "HANGER";
    EServiceTypes["AIRLINE"] = "AIRLINE";
    EServiceTypes["FLIGNT_PLANNING"] = "FLIGHT PLANNING";
    EServiceTypes["OTHER"] = "OTHER";
    EServiceTypes["SPECIAL"] = "SPECIAL";
    EServiceTypes["NONE"] = "";
})(EServiceTypes || (exports.EServiceTypes = EServiceTypes = {}));
var EAvianisServiceRequestStatus;
(function (EAvianisServiceRequestStatus) {
    EAvianisServiceRequestStatus["CANCELLED"] = "cancelled";
    EAvianisServiceRequestStatus["PENDING"] = "pending";
    EAvianisServiceRequestStatus["NONE"] = "";
})(EAvianisServiceRequestStatus || (exports.EAvianisServiceRequestStatus = EAvianisServiceRequestStatus = {}));
;
