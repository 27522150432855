import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {Quote, Trip, ETripStatus, TRIP_STATUS_MAP, getScoreColor} from '@nxt/model-laro';
import {InternalItineraryTableComponent} from '../../../_components/trip/internal-itinerary-table.component';
import {AvatarStackComponent} from '@library/shared/avatars/avatar.stack.component';
import {IconsComponent} from '@library/shared/icons/icons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule,
        InternalItineraryTableComponent,
        AvatarStackComponent, IconsComponent
    ],
    selector: 'trip-item',
    template: `
        <a (click)="onClick.emit(item)">
            <div class="flex justify-between">
                <div class="flex">
                    <div class="justify-center align-top text-gray-500 mr-2">
                        <avatar-stack [items]="item.agents" size="6"></avatar-stack>
                    </div>
                    <div class="flex flex-col">
                        <div class="text-dark italic text-xs" *ngIf="!hideCustomer">
                            {{item.name}}
                        </div>
                        <div class="flex m-1" *ngIf="item.trip_number || item.quote_number">
                            <ng-container *ngIf="item.quote_number">
                                <icon name="heroicon-solid-fire" class="h-4 w-4 m-0"></icon>
                                <span class="text-xs ml-1 mr-4">{{ item.quote_number }}</span>
                            </ng-container>
                            <ng-container *ngIf="item.trip_number">
                                <icon name="custom-flight-takeoff" class="h-4 w-4 m-0"></icon>
                                <span class="text-xs ml-1">{{ item.trip_number }}</span>
                            </ng-container>
                        </div>
<!--                        <internal-itinerary-table [trip]="item"></internal-itinerary-table>-->
                    </div>
                </div>
                <div class="flex">
                    <div [style.color]="scoreColor" class="flex font-bold place-content-center px-3"  *ngIf="scoreColor">
                        <icon name="heroicon-outline-lightning-bolt" class="h-8 w-8 mr-1"></icon>
                        <span>{{ item.score?.total || 0 }}</span>
                    </div>
                    <div class="flex flex-col place-items-center space-y-2">
                        <div class="chicklet" *ngIf="item.status"
                             [style.backgroundColor]=" TRIP_STATUS_MAP[item.status]?.color"
                             [style.color]=" TRIP_STATUS_MAP[item.status]?.contrast">
                            {{ TRIP_STATUS_MAP[item.status]?.label  }}
                        </div>
                        <div class="hidden md:inline">{{ item.price?.total | currency:'USD':'symbol'}}</div>
                    </div>
                </div>
            </div>
        </a>
    `
})
export class TripItemComponent implements OnChanges {
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @Input() item: Quote | Trip;
    @Input() hideCustomer: boolean;
    ETripStatus = ETripStatus;
    TRIP_STATUS_MAP = TRIP_STATUS_MAP;
    scoreColor: string;

    ngOnChanges(changes: SimpleChanges) {
        if (this.item instanceof Quote) {
            this.scoreColor = getScoreColor(this.item.score);
        }
    }

}
