import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EMedium, Thread, ThreadMessage} from '@nxt/model-core';
import {NgIf} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'message-body',
    standalone: true,
    imports: [
        NgIf
    ],
    template: `
        <div class="w-full mt-1 text-gray-600 text-sm" *ngIf="parent.medium !== EMedium.EMAIL && parent.medium !== EMedium.WEB">
            <span *ngIf="!parent._html" [innerHTML]="parent._summary"></span>
            <span [innerHTML]="parent._html"></span>
        </div>
        <div class="w-full mt-1 text-gray-600 text-sm" *ngIf="parent.medium === EMedium.EMAIL">
            <span class="text-xs"  [innerHTML]="parent._summary"></span>
        </div>
    `
})
export class MessageBodyComponent implements OnChanges {
    @Input() parent: ThreadMessage|Thread;
    EMedium = EMedium;

    constructor(private sanitizer: DomSanitizer) {
        
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (this.parent) {
            if (this.parent.html?.replace(/^undefined$/,'') ) {
                this.parent._html = this.sanitizer.bypassSecurityTrustHtml(this.parent.html);
            }
            if (this.parent.summary) {
                if (this.parent['summary_match']) {
                    this.parent['_summary'] = this.sanitizer.bypassSecurityTrustHtml(this.parent['summary_match']);
                } else {
                    this.parent['_summary'] = this.sanitizer.bypassSecurityTrustHtml(this.parent.summary);
                }
            } else if (this.parent['message']) {
                this.parent['_summary'] = this.sanitizer.bypassSecurityTrustHtml(this.parent['message']);
            }
        }
    }

}
