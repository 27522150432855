import {Component, Input, Output, OnInit, EventEmitter, Inject, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UntypedFormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router} from '@angular/router';

import {Contact as LaroContact, Trip, Quote} from '@nxt/model-laro';
import {IMenuItem, Thread} from '@nxt/model-core'

import {PageService} from '@library/shared/_services/page.service';
import {ClientService} from '@library/shared/_services/client.service';
import {MessagingService} from '@library/nxt/_services/messaging.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        InputStackedTextComponent, FooterButtonsComponent
    ],
    template: `
        <div class="p-3" [formGroup]="form" *ngIf="form">
            <p class="italic text-sm p-3">Enter trip number from your Charter Management Software and click Link.</p>
            <input-stacked-text
                    label="Trip Number"
                    [form]="form" controlName="trip_number"
                    (onEnter)="submit()"
            ></input-stacked-text>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>

    `
})
export class ImportTripDialog implements OnChanges {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() contact: LaroContact;
    @Input() thread: Thread;
    existing_trip: Trip;
    existing_quote: Quote;
    trip_number: string;
    maxWidth: string = '400px';
    form: UntypedFormGroup;
    buttons: IMenuItem[] = [];

    constructor(
        private pSvc: PageService,
        private cSvc: ClientService,
        private fb: UntypedFormBuilder,
        private fSvc: FireService,
        private router: Router
    ) {
    }

    ngOnChanges(changes?:SimpleChanges) {
        this.form = this.fb.group({
            trip_number: [this.existing_trip?.trip_number || this.existing_quote?.trip_number || this.trip_number || '']
        });
        this.buildButtons();
    }

    buildButtons() {
        this.buttons = [
            {
                label: 'Link',
                class: 'btn-dark',
                click: () => {
                    this.submit();
                }
            },
            {
                label: 'View Trip',
                class: 'btn-light',
                show: () => {
                    return !!this.existing_trip?.id && !window.location.pathname.match(this.existing_trip?.id);
                },
                click: () => {
                    this.onClose.emit();
                    this.router.navigate([`/${this.cSvc.name_key}/trips/${this.existing_trip.id}`]);
                }
            }
        ];
    }

    async submit() {
        if (this.form.get('trip_number').value) {

            this.pSvc.blocking$.next(true);
            try {

                let result: any = await this.cSvc.callAPI('/laro/trip/import', 'post', {
                    parent: this.contact?.toMinJSON ? this.contact?.toMinJSON() : this.contact || null,
                    thread: this.thread?.toJSON() || null,
                    trip_number: this.form.get('trip_number').value,
                    quote_number: this.existing_trip?.quote_number || '',
                    trip_id: this.existing_trip?.id || this.existing_quote?.trip_id || '',
                    quote_id: this.existing_quote?.id || this.existing_trip?.quote_id || ''
                });

                this.onClose.emit(result);
                await this.router.navigate([`/${this.cSvc.name_key}/trips/${result.id}`]);

            } catch (e) {
                if (e.error?.id) {
                    this.onClose.emit(( await this.fSvc.getObject(`clients/${this.cSvc.client_id}/trips/${e.error.id}`)) as Trip);
                    await this.router.navigate([`/${this.cSvc.name_key}/trips/${e.error.id}`]);
                } else {
                    this.pSvc.alert$.next(e);
                }
            }
            this.pSvc.blocking$.next(false);
        } else {
            this.form.markAllAsTouched();
            this.form.get('trip_number').setErrors({required: true});
        }
    }
}
