import {Component, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';

import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';

import {ClientService} from '@library/shared/_services/client.service';
import {UserService} from '@library/nxt/_services/user.service';
import {PageService} from '@library/shared/_services/page.service';
import {IFirestoreQuery} from '@library/nxt/_services/fire.service';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {IMenuItem, Thread, ThreadMessage, User, Client} from '@nxt/model-core';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {MessageItemSummary} from './message-item-summary';
import {ThreadItemComponent} from './thread-item';
import {ScrollableGenericList} from '@library/nxt/list/scrollable-generic-list';
import {Router} from '@angular/router';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {DateHeaderComponent} from '@library/shared/header/date-header.component';
import {PipesModule} from '@library/shared/_pipes/pipes';
import {SpinnerComponent} from '@library/shared/spinner/spinner.component';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'unread-list',
    standalone: true,
    imports: [
        CommonModule, IconsComponent, PopButtonComponent,
        ScrollableGenericList, MessageItemSummary, ThreadItemComponent, DateHeaderComponent, PipesModule, SpinnerComponent, IconsComponent
    ],
    template: `
        <div class="border-b border-gray-200">
            <div class="w-full flex justify-between place-items-center pt-4">
                <div class="flex pl-3"></div>
                <div class="flex">
                    <button class="btn-clear -mt-4" *ngIf="showDeleteBtn" (click)="removeSelected()">
                        <icon name="heroicon-outline-trash" class="h-5 w-5 text-red-600"></icon>
                    </button>
                    <ng-container *ngIf="clientMenu?.length">
                        <pop-button iconName="heroicon-solid-dots-vertical"
                                    iconClass="h-4 w-4 text-gray-500 ml-1 mb-1"
                                    [items]="clientMenu"
                                    label="Other Unreads"
                                    btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                        ></pop-button>
                    </ng-container>
                    <pop-button iconName="heroicon-solid-dots-vertical"
                                iconClass="h-4 w-4 text-gray-500 ml-1 mb-1"
                                [items]="actionsMenu"
                                label="Bulk Actions"
                                btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                    ></pop-button>
                </div>
            </div>
        </div>

        <div class="pb-14">
            <scrollable-generic-list
                    #msgList
                    [baseQuery]="baseQuery"
                    label="Unreads"
                    [pageSize]="20"
                    [itemTemplate]="itemTemplate"
                    [path]="path"
                    [watch]="false"
                    [autoStart]="true"
            ></scrollable-generic-list>
        </div>

        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <div class="flex w-full border-b border-gray-200" *ngIf="item?.id">
                <div class="flex place-items-start">
                    <button (click)="toggle(item)"
                            class="btn-sm btn-clear w-8 h-8 p-0"
                    >
                        <icon [name]="markedForRemoval[item.id] ? 'heroicon-outline-check-circle' : 'heroicon-outline-x'" class="h-6 w-6 text-gray-600 hover:text-gray-400"></icon>
                    </button>
                </div>
                <div class="w-full h-full cursor-pointer hover:bg-gray-100">
                    <message-item-summary
                            *ngIf="item['_type']==='threadsmsgs'"
                            (onSelect)="mSvc.showThread(item)"
                            [unread]="item.unread || item._unread?.seen === false"
                            [message]="item"
                            [context]="EThreadContext.UNREAD"
                    ></message-item-summary>
                    <thread-item
                            *ngIf="item['_type']==='threads'"
                            [thread]="item"
                            [loadAll]="true"
                            [context]="EThreadContext.UNREAD"
                            (onSelect)="mSvc.showThread(item)"
                    ></thread-item>
                </div>
            </div>
        </ng-template>
    `
})
export class UnreadListComponent extends OnDestroyPage implements OnChanges {
    @ViewChild('msgList', { read: ScrollableGenericList }) msgList: ScrollableGenericList;
    @Input() roles: string[] = ['sales'];
    @Input() user: User;
    @Input() client: Client;
    @Input() clients: Client[];
    @Input() counts: any;
    @Input() show: boolean;

    activeItem: Thread | ThreadMessage;
    height: string;
    EThreadContext = EThreadContext;
    filtered: any[];
    tabs: IMenuItem[] = [];
    uBR: any;
    baseQuery: IFirestoreQuery[] = [
        { name: 'orderBy', args: ['date','desc'] }
    ];
    path: string;
    clientMenu: IMenuItem[];
    markedForRemoval: any = {};
    showDeleteBtn: boolean;
    actionsMenu: IMenuItem[] = [
        {
            label: 'Select All',
            click: async () => {
                this.pSvc.loading$.next(true);
                if (this.markedForRemoval.all) {
                    delete this.markedForRemoval.all;
                    this.markedForRemoval = {};
                    this.showDeleteBtn = false;
                    this.updateActionMenuLabel('Select All');
                } else {
                    this.markedForRemoval.all = true;
                    await Promise.all(this.msgList?.items?.map(async (t:any) => {
                        this.markedForRemoval[t.id] = true;
                    }));
                    this.showDeleteBtn = true;
                    this.updateActionMenuLabel('Unselect All');

                }
                this.pSvc.loading$.next(false);
            }
        },
        {
            label: 'Mark Selected as Read',
            click: async () => {
                this.pSvc.loading$.next(true);
                await Promise.all(Object.keys(this.markedForRemoval).map(async (id:any) => {
                    try {
                        let i: number = this.msgList?.items?.findIndex(item => item.id === id);
                        if (i > -1) {
                            await this.msgList.items[i].markRead();
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                }));
                this.pSvc.loading$.next(false);
            }
        },
        {
            label: 'Mark Selected as Unread',
            click: async () => {
                this.pSvc.loading$.next(true);
                await Promise.all(Object.keys(this.markedForRemoval).map(async (id:any) => {
                    try {
                        let i: number = this.msgList?.items?.findIndex(item => item.id === id);
                        if (i > -1) {
                            await this.msgList.items[i]._unread?._docRef?.update({seen: false});
                        }
                    } catch (e) {
                        console.warn(e);
                    }
                }));
                this.pSvc.loading$.next(false);
            }
        },
        {
            label: 'Remove Selected',
            click: async () => {
                return this.removeSelected();
            }
        },
    ];

    constructor(
        public cSvc: ClientService,
        public uSvc: UserService,
        public mSvc: MessagingService,
        public pSvc: PageService,
        private router: Router
    ) {
        super();
        this.mSvc.counts$
            .pipe(takeUntil(this.d$))
            .subscribe(counts => {
                if (this.counts?.total !== counts?.total || this.counts?.unread !== counts?.unread) {
                    this.msgList?.loadData();
                }
                this.counts = counts;
            })
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.user?.id && this.client?.id && this.show) {
            this.setPath();
            this.loadClients();
        }
    }
    
    setPath() {
        this.path = `users/${this.user.id}/clients/${this.cSvc.client_id}/unread` ;
    }

    async loadClients() {
        if (
            this.counts
            && this.client?.id
            && this.clients?.length
        ) {
            this.clientMenu = Object.keys(this.counts).reduce((items,id) => {
                if (id !== this.client.id) {
                    let c = this.clients.find(c => c.id === id);
                    if (c && this.counts[c.id].total) {
                        items.push({
                            label: `${c.name} (${this.counts[id].total})`,
                            click: async () => {
                                await this.router.navigate([`/${c.name_key}/threads/inbox/unread`]);
                            }
                        });
                    }
                }
                return items;
            }, []);
        } else {
            this.clientMenu = [];
        }
    }

    async toggle(item: Thread|ThreadMessage) {
        try {
            if (this.markedForRemoval[item?.id]) {
                delete this.markedForRemoval[item?.id];
                this.showDeleteBtn = !!(Object.keys(this.markedForRemoval).length);
            } else {
                this.markedForRemoval[item?.id] = true;
                this.showDeleteBtn = true;
            }
        } catch (e) {
            console.warn(e, item);
            this.pSvc.notification$.next({
                title: 'Oops!',
                message: 'Had trouble deleting this item. It may already be removed. Try refreshing your page.'
            });
        }
    }

    async removeSelected() {
        this.pSvc.loading$.next(true);

        // See if user wants to delete all.
        let deleteAll: boolean = this.markedForRemoval.all;
        delete this.markedForRemoval.all;

        await Promise.all(Object.keys(this.markedForRemoval).map(async id => {
            let i: number = this.msgList?.items?.findIndex(item => item.id === id);
            try {
                if (i > -1) {
                    let [item] = this.msgList.items.splice(i,1);
                    await item?._unread?._docRef?.delete();
                }
            } catch (e) {
                console.warn(e);
            }
        }));
        this.markedForRemoval = {};

        if (deleteAll) {
            let preserve: string[] = this.msgList?.items?.map(item => item.id);
            await this.mSvc.deleteUnreads(this.user, this.client, preserve);
            this.msgList.items = [];
        }
        this.showDeleteBtn = false;
        this.pSvc.loading$.next(false);
    }

    updateActionMenuLabel(newLabel: string) {
        this.actionsMenu[0].label = newLabel;
    }
}
